import {
  reactive, readonly, computed,
} from 'vue';

const state = reactive({
  walletConnecting: false,
  walletConnected: false,
  firstModalVisible: true,
  connectWalletModalVisible: false,
  mintModalVisible: false,
  showMintedModal: false,
  transactionUrl: 'https://etherscan.io/tx/',
  transactionHash: '',
  etherscanUrl: '',
  networkId: 1,
  connectedWallet: '',
  baseURL: 'https://s1894n5dec.execute-api.us-east-1.amazonaws.com/development',
  worldType: '',
  mintingContract: '',
  mintCollectionUrl: '',
  mintingPrice: '0.0001',
  supply: 0,
  totalSupply: 0,
  showTransaction: false,
  presale: false,
  claimed: false,
  presaleTimeExpiration: 0,
});

// eslint-disable-next-line
const transactionUrlCombined = computed(()=>{
  return state.transactionUrl + state.transactionHash;
});

const methods = {
  updatePresaleTime(time) {
    state.presaleTimeExpiration = Number(time);
  },
  updateCollectionURL(value) {
    state.mintCollectionUrl = value;
  },
  updateClaimed(value) {
    state.claimed = value;
  },
  updateMintingPrice(price) {
    state.mintingPrice = price;
  },
  updateMintingContract(str) {
    state.mintingContract = str;
  },
  updateWorldType(str) {
    state.worldType = str;
  },
  updatePresale(value) {
    state.presale = value;
  },
  gotoCollection() {
    window.open(state.mintCollectionUrl, '_blank');
  },
  gotoTransaction() {
    window.open(transactionUrlCombined.value, '_blank');
  },
  updateTransactionHash(tx) {
    state.transactionHash = tx;
  },
  updateShowMintedModal(show) {
    state.showMintedModal = show;
  },
  updateShowTransaction(show) {
    state.showTransaction = show;
  },
  updateSupply(supply) {
    state.supply = supply;
  },
  updateTotalSupply(supply) {
    state.totalSupply = supply;
  },
  updateConnectedWallet(wallet) {
    state.connectedWallet = wallet;
  },
  updateWalletConnecting(bool) {
    state.walletConnecting = bool;
  },
  updateWalletConnected(bool) {
    state.walletConnected = bool;
    if (!bool) {
      state.mintModalVisible = true;
      state.connectWalletModalVisible = false;
    }
    if (bool) {
      setTimeout(() => {
        state.walletConnected = false;
        state.mintModalVisible = true;
        state.connectWalletModalVisible = false;
      }, 3000);
    }
  },
  updateMintModalVisible(bool) {
    state.mintModalVisible = bool;
  },
  updateConnectWalletModalVisible(bool) {
    state.firstModalVisible = !bool;
    state.connectWalletModalVisible = bool;
  },
};

export default {
  state: readonly(state), methods,
};
