/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { createRouter, createWebHistory } from 'vue-router';
import Web3 from 'web3';
import NFT from '../views/NFT.vue';
import SmartContract from '../contracts/WorldContract.json';
import store from '../store/index';

const web3 = new Web3(window.ethereum);

const loadMintingContracts = () => {
  const url = window.location.href;
  // store.methods.updateWorldType('nexus');
  // store.methods.updateMintingPrice('0.018');
  // store.methods.updateMintingContract('0x937408048C3725419Ee021aD8E65E5FC4474F80B');
  // store.methods.updateCollectionURL('https://opensea.io/collection/the-nexus');
  if (url.includes('nexus')) {
    store.methods.updateWorldType('nexus');
    store.methods.updateMintingPrice('0.018');
    store.methods.updateMintingContract('0x277880d722c6a8bdeac95da708f201bf21824a37');
    store.methods.updateCollectionURL('https://opensea.io/collection/the-nexus');
  }
  if (url.includes('backyard')) {
    store.methods.updateWorldType('byc');
    store.methods.updateMintingPrice('0.035');
    store.methods.updateMintingContract('0x267a90f2cc982fa5f36434cf5a18ae826c95d477');
    store.methods.updateCollectionURL('https://opensea.io/collection/backyard-and-chill');
  }
  if (url.includes('obelisk')) {
    store.methods.updateWorldType('obelisk');
    store.methods.updateMintingPrice('0.035');
    store.methods.updateMintingContract('0x2996b1058ed268813b90de00ff742c8f1bebde2e');
    store.methods.updateCollectionURL('https://opensea.io/collection/obelisk-v3');
  }
  if (url.includes('hanami')) {
    store.methods.updateWorldType('hanami');
    store.methods.updateMintingPrice('0.069');
    store.methods.updateMintingContract('0x4c0dfcec921958c09beb5783a8388767afaf575a');
    store.methods.updateCollectionURL('https://opensea.io/collection/hanami-v2-1');
  }
  if (url.includes('barbeyond')) {
    store.methods.updateWorldType('barbeyond');
    store.methods.updateMintingPrice('0.069');
    store.methods.updateMintingContract('0xf9c35ef35dcf490ab0ce1f4126cc400539e9cfce');
    store.methods.updateCollectionURL('https://opensea.io/collection/bar-beyond');
  }
  // Get supply
  const getSupplyObj = new web3.eth.Contract(
    SmartContract.abi,
    store.state.mintingContract,
  );
  getSupplyObj.methods.totalSupply().call((err, result) => {
    store.methods.updateSupply(result);
  });

  getSupplyObj.methods.maxSupply().call((err, result) => {
    store.methods.updateTotalSupply(result);
  });
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/thenexus',
      component: NFT,
      props: { nftType: 'nexus' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/backyard&chill',
      component: NFT,
      props: { nftType: 'backyard' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/obelisk',
      component: NFT,
      props: { nftType: 'obelisk' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/hanami',
      component: NFT,
      props: { nftType: 'hanami' },
      beforeEnter: loadMintingContracts,
    },
    {
      path: '/barbeyond',
      component: NFT,
      props: { nftType: 'bar beyond' },
      beforeEnter: loadMintingContracts,
    },
    // {
    //   path: '/',
    //   redirect: () => ({ path: '/thenexus' }),
    // },
  ],
});

export default router;
